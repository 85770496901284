* {
    font-family: 'Asap', 'Montserrat', sans-serif;
    color: #041D3A;
}

body {
    background-color: #ebebeb;
    margin: 0;
    padding: 0;
}

body * {
    /* overflow-x: scroll !important; */
    /* overflow-y: scroll !important; */
}
.font-blue{
    color: #19418C;
}
.font-neutral {
    color: #6A7682;
}
.font-turquoise{
    color: #1ED2AF;
}
.font-w-900{
    font-weight: 900;
}

/************ Banner css ************/

.banner-container span{
    font-weight: 900;
}

.banner-container .partner-images img{
    height: 3rem;
}

@media (min-width: 576px) {
    .banner-container .banner-logo{
        width: 40rem;
    }
}

@media (max-width: 576px) {
    .banner-container .partner-images img{
        height: 1.5rem;
    }
    .banner-container .banner-logo{
        width: 15rem;
    }
}

/************ Info css ************/

.info-container{
    transform: translatey(-15%);
}

.info-card.p-card{
    background-color: #59d3af;
    position: relative;
    margin-left: 4rem;
}

.info-card.p-card .p-card-body{
    padding: 2rem 3rem 2rem 3rem !important;
    z-index: 6;
}

.info-card.p-card:after {
    border-left: 25px solid #0000;
    border-right: 25px solid #0000;
    border-top: 25px solid #59d3af;
    right: -25px;
    top: 25px;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
    z-index: 1;
}

.info-card .p-card-content p{
    color: #FFF;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.info-card .p-card-content span{
    color: #FFF;
    font-weight: bold;
}

.info-container img{
    left: 52%
}

@media (min-width: 1560px) {
    .info-container img{
        width: 48% !important;
    }
}

@media (min-width: 576px) {
    .info-container{
        transform: translatey(-15%);
    }
    .info-card.p-card{
        top:40% !important;
    }
    .info-description{
        top: 60% !important;
        left: 4rem;
        padding: 0rem 4rem 2rem 1rem !important;
    }
}

@media (min-width: 1560px) {
    .info-card.p-card{
        margin-left: 10rem;
    }
    .info-card.p-card {
        width: 45rem !important;
    }
    .info-description{
        top: 59% !important;
        left: 10rem;
        padding: 0rem 5rem 2rem 1rem !important;
    }
}

@media (max-width: 576px) {
    .banner-container .partner-images img{
        height: 1.5rem;
    }
    .banner-container .banner-logo{
        width: 15rem;
    }
    .info-card.p-card{
        margin-left: 0.25rem;
    }
    .info-card.p-card .p-card-body{
        padding: 0.20rem !important;
    }
    .info-description{
        display: none;
    }
}

/************ Advantages css ************/

.advantages-container .p-card {
    background-color: #E6E6E6;
}

.advantages-container .p-card .p-card-title {
    font-size: 1.3rem;
    color: #19418C;
    font-weight: 900;
}

.advantages-container .p-card .p-card-subtitle {
    font-size: 1.3rem;
    color: #19418C;
    font-weight: 500;
}

@media (min-width: 576px) {
    .advantages-container .p-card {
        height: 80%;
    }
    .advantages-container .carousel-mobile {
        display: none !important;
    }
}

@media (max-width: 599px) {
    .advantages-container .carousel-desktop {
        display: none !important;
    }
    .advantages-container .carousel-mobile {
        display: block !important;
    }
}

/************ Tools css ************/

.tools-container {
    background: linear-gradient(to bottom, #ebebeb 0,#ebebeb 22rem, white 1rem, white 100%);
}

.tools-buttons-card .p-card-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    flex: none !important;
    order: 1 !important;
    flex-grow: 0 !important;
}

.tools-buttons-card .p-button-label {
    color: #FFFFFF;
}

.tools-cards-container .p-card {
    box-shadow: none;
}

.tools-cards-container .p-card .p-card-body {
    padding: 0px !important;
    font-weight: 400;
}

.tools-cards-container .p-card .p-card-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #19418C;
}

.tools-cards-container .p-card .p-card-content {
    padding: 0px !important;
    line-height: 18.29px;
}

.tools-cards-container .p-card .p-card-content ul li {
    color: black;
}


@media (min-width: 576px) {
    .tools-cards-container .carousel-mobile {
        display: none !important;
    }
    .tools-img-computer img{
        height: 40rem !important;
    }
    .tools-cards-container .p-card .p-card-body {
        padding: 1rem 1rem 1rem 1rem !important;
    }
    .tools-cards-container .p-card .p-card-title {
        font-size: 1.5rem;
    }
    .tools-cards-container .p-card .p-card-content {
        padding: 0px !important;
    }
}

@media (min-width: 1560px) {
    .tools-cards-container .p-card .p-card-body {
        padding: 1rem 4rem 1rem 4rem !important;
    }
    .tools-img-computer img{
        height: 45rem !important;
    }
    .tools-cards-container .p-card .p-card-title {
        font-size: 2rem;
    }
}

@media (max-width: 599px) {
    .tools-container {
        background: linear-gradient(to bottom, #ebebeb 0,#ebebeb 7.3rem, white 1rem, white 100%);
    }
    .tools-cards-container .carousel-desktop {
        display: none !important;
    }
    .tools-cards-container .carousel-mobile {
        display: block !important;
    }
}

/************ Steps css ************/

.steps-container .bonus-track-container {
    position: absolute;
}

.steps-container .bonus-track {
    font-size: 25px;
    color: #19418C;
    font-weight: 900;
    background-color: #fff;
    border-radius: 7px;
    padding: 10px 10px;
    position: relative;
}

.steps-turquoise {
    background-color: #4160e5;
    border-radius: 17px;
    color: #EDF1EE;
}

.steps-turquoise .p-card-title {
    color: #EDF1EE;
    font-size: 2rem;
}

.steps-turquoise .p-card-title span{
    color: #EDF1EE;
    font-weight: 900;
}

.steps-turquoise .p-card-body {
    padding: 0px !important;
}


@media (min-width: 576px) {
    .steps-turquoise:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 10%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 50px solid #4160e5;
    }

    .steps-turquoise{
        position: relative;
        margin-left: 15rem !important;
        margin-right: 5rem !important;
    }
    .steps-container .bonus-track-container.bonus-mobile{
        display: none !important;
    }
    .steps-container .bonus-track-container.bonus-desktop{
        position: static;
        margin-right: 0;
        margin-left: auto;
    }
    .steps-container .bonus-track {
        transform: translate(10%,-50%);
    }
    .steps-container .second-steps .p-card-title{
        font-size: 1.5rem !important;
    }
}

@media (min-width: 1560px) {
    .steps-turquoise{
        margin-left: 18rem !important;
        margin-right: 18rem !important;
    }
}

@media (max-width: 576px) {
    .steps-container .bonus-track-container.bonus-desktop{
        display: none !important;
    }
    .steps-container .bonus-track-container {
        position: absolute;
    }
    .steps-container .bonus-track {
        font-size: 1.35rem;
        transform: translate(135%,-50%);
    }

    .steps-turquoise {
        border-radius: 17px;
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
    .steps-turquoise .p-card-title {
        color: #EDF1EE;
        font-size: 0.90rem;
        font-weight: 500;
        text-align: center;
    }
    .steps-turquoise .p-card-title span{
        color: #EDF1EE;
        font-size: 0.90rem;
        font-weight: 900;
    }
    .steps-container .bonus-track {
        transform: translate(125%,-50%);
    }

}

/************ Companies css ************/